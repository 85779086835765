import React from 'react';
import { Table, Tag } from 'antd';

import type { ColumnsType } from 'antd/es/table';
import type { StructuredData } from '../../models';

type Props = {
  dataSource: StructuredData[];
}

const columns: ColumnsType<StructuredData> = [
  {
    dataIndex: '车牌号码',
    title: '车牌号码',
    width: '8%',
  },
  {
    dataIndex: '定位时间',
    title: '定位时间',
    width: '15%',
  },
  {
    dataIndex: '接受时间',
    title: '接受时间',
    width: '15%',
  },
  {
    dataIndex: '方向',
    title: '方向',
    width: '5%',
  },
  {
    dataIndex: '速度',
    title: '速度',
    width: '5%',
  },
  {
    dataIndex: '里程',
    title: '里程',
    width: '8%',
  },
  {
    dataIndex: '信号',
    title: '信号',
    width: '5%',
  },
  {
    dataIndex: '卫星',
    title: '卫星',
    width: '5%',
  },
  {
    dataIndex: '位置',
    title: '位置',
    width: '20%',
    render: (text) => text.split(',').map((t: string) => <Tag key={t}>{t}</Tag>),
  },
  {
    dataIndex: '状态',
    title: '状态',
    width: '18%',
    render: (text) => text.split('、').map((t: string) => <Tag key={t}>{t}</Tag>),
  },
];

const DataTable: React.FC<Props> = ({ dataSource }) => {
  return (
    <Table rowKey="定位时间" dataSource={dataSource} columns={columns} pagination={{ position: ['bottomRight'] }} scroll={{ y: 400 }} />
  )
};

export default DataTable;
