import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';

import ExcelUploader from './components/ExcelUploader';
import DataTable from './components/DataTable';
import Searcher from './components/Searcher';
import TrackMap from './components/TrackMap';

import type { StructuredData, Filters } from './models';

import './App.css';

const filterData = (data: StructuredData[], filters: Filters | null): StructuredData[] => {
  if (!filters) {
    return data;
  }
  return data.filter((item) => {
    const date = moment(item['定位时间']).valueOf();
    return date >= filters.startTime && date <= filters.endTime;
  });
};

const App:React.FC = () => {
  const [data, setData] = useState<StructuredData[]>([]);
  const [filteredData, setFilteredData] = useState<StructuredData[]>([]);
  const [filters, setFilters] = useState<Filters | null>(null);
  const [trackMapVisible, setTrackMapVisible] = useState(false);

  useEffect(() => {
    setFilteredData(filterData(data, filters));
  }, [data, filters]);

  const saveStructuredData = useCallback((structuredData: StructuredData[]) => setData(structuredData), []);
  const updateFilters = useCallback((filters: Filters | null) => setFilters(filters), []);

  const toggleTrackMapVisible = useCallback(() => setTrackMapVisible(state => !state), []);

  return (
    <div className="App">
      { data.length ?
        <>
          <Searcher submit={updateFilters} />
          <DataTable dataSource={filteredData} />
        </>
        :
        <ExcelUploader saveStructuredData={saveStructuredData} />
      }
      {
        filteredData.length ?
          <TrackMap data={filteredData} close={toggleTrackMapVisible} />
          :
          null
      }
    </div>
  );
}

export default App;
