import React, { useEffect } from 'react';

import type { StructuredData } from '../../models';

import './TrackMap.css';

// @ts-ignore
const { BMap } =window;

type Props = {
  data: StructuredData[];
  close: () => void;
}

const TrackMap:React.FC<Props> = ({ data, close }) => {
  useEffect(() => {
    if (data.length) {
      const referenceItem = data[0];
      const map = new BMap.Map("container");
      const startPoint = new BMap.Point(...referenceItem['位置'].split(','));
      const endPoint = new BMap.Point(...data[data.length - 1]['位置'].split(','));
      // 以第一个点为中心
      map.centerAndZoom(startPoint, 12);

      map.enableScrollWheelZoom(true);

      // 绘制轨迹路径
      const polyline = new BMap.Polyline(
        data.map(item => new  BMap.Point(...item['位置'].split(','))),
        {
          strokeColor: '#096dd9',
          strokeWeight: 3,
          strokeOpacity: 1,
        }
      );
      map.addOverlay(polyline);

      // 标注起点
      const startPointMarker = new BMap.Marker(startPoint, { title: '起点' });
      map.addOverlay(startPointMarker);

      const startPointLabel = new BMap.Label('起点', { position: startPoint, offset: {
        width: -28,
        height: 0,
      } });
      map.addOverlay(startPointLabel);

      // 标注终点
      const endPointMarker = new BMap.Marker(endPoint, { title: '终点' });
      map.addOverlay(endPointMarker);

      const endPointLabel = new BMap.Label('终点', { position: endPoint });
      map.addOverlay(endPointLabel);
    }
  }, [data]);

  return (<main className="TrackMap" id="container" />);
};

export default TrackMap;
