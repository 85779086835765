import React, { useCallback } from 'react';
import { Form, Button, DatePicker } from 'antd';

import type { Moment } from 'moment';
import type { Filters } from '../../models';

const { RangePicker } = DatePicker;

type Props = {
  submit: (filters: Filters | null) => void;
};

const Searcher: React.FC<Props> = ({ submit }) => {
  const [form] = Form.useForm();

  const handleReset = useCallback(() => {
    form.resetFields();
    submit(null);
  }, [form, submit]);

  const handleSubmit = useCallback(() => {
    form.validateFields()
      .then((values) => {
        const { timeRange }: { timeRange: Moment[] } = values;
        submit({
          startTime: timeRange[0].valueOf(),
          endTime: timeRange[1].valueOf(),
        });
      })
      .catch(e => {});
  }, [form, submit]);

  return (
    <Form form={form} layout="inline" style={{ margin: '16px 0' }} initialValues={{ timeRange: undefined }}>
      <Form.Item name="timeRange" label="定位时间范围">
        <RangePicker showTime />
      </Form.Item>
      <Form.Item>
        <Button onClick={handleReset}>Reset</Button>
      </Form.Item>
      <Form.Item>
        <Button type="primary" onClick={handleSubmit}>Submit</Button>
      </Form.Item>
    </Form>
  );
};

export default Searcher;
