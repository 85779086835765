import React, { useState, useCallback } from 'react';
import { read, WorkBook, utils } from 'xlsx';
import { Upload, message } from 'antd';
import { InboxOutlined } from '@ant-design/icons';

import type { StructuredData } from '../../models';

const { Dragger } = Upload;

type Props = {
  saveStructuredData: (structuredData: StructuredData[]) => void;
};

const structureData = (workbook: WorkBook): StructuredData[] => {
  const { SheetNames, Sheets } = workbook;
  if (SheetNames.length) {
    const targetSheet = Sheets[SheetNames[0]];
    return utils.sheet_to_json(targetSheet);
  }

  return [];
};

const ExcelUploader: React.FC<Props> = ({ saveStructuredData }) => {
  const [progress, setProgress] = useState<string>('');

  const beforeUpload = useCallback((file: File) => {
    setProgress('Start reading file...');
    file.arrayBuffer()
      .then(data => {
        setProgress('Read file successfully and start structuring...');
        const workbook = read(data);
        saveStructuredData(structureData(workbook));
      })
      .catch(e => {
        message.error('Read or structure file failed');
        setProgress('');
        console.error(e);
      });
    return false;
  }, [saveStructuredData]);

  return (
    <Dragger
      accept='.xlsx'
      beforeUpload={beforeUpload}
      maxCount={1}
      fileList={[]}
      disabled={!!progress}
    >
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">{progress || 'Click or drag file to this area to upload'}</p>
    </Dragger>
  );
};

export default ExcelUploader;
